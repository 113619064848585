 const noCache = `nocache=${new Date().getTime()}`
 
 const headerLinks = [
    {
        label: 'О законе',
        href: '#project'
    },
    {
        label: 'Подключение',
        href: '#services'
    },
    {
        label: 'Контакты',
        href: '#contact'
    },
    {
        label: 'Политика',
        href: `/files/Первый ОРД. Политика Обработка персональных данных.pdf?${noCache}`,
        target:'_blank',
        rel:"noreferrer",
    },
    {
        label: 'Стоимость услуг',
        href:`/files/Первый ОРД. Стоимость услуг.pdf?${noCache}`,
        target:'_blank',
        rel:"noreferrer",
    },
] 

export default headerLinks