import React from 'react';
import { emailForNewClients, supportEmail, telegram } from '../../../constants';

const HomeFooter = () => {
   const currentYear = new Date().getFullYear()

   return (
      <>
         <footer>
            <div className="footer__area grey-bg pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 pr-0">
                        <div className="footer__img">
                           <img src="assets/img/logo/logo-black.svg" alt=""/>
                        </div>

                        </div>
                        <div className="col-xl-3 col-sm-4 " >                       

                        <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".2s">
                        <div className="footer__widget-title">
                           <h4>Правила</h4>
                        </div>
                           <div className="footer__widget-content">
                              <p><a href='/files/Политика Обработка персональных данных.pdf' target={'_blank'}>Политика "Обработка персональных данных"</a></p>
                           </div>
                        </div>
                        </div>
                        <div className="col-xl-2 col-sm-4 col-lg-2">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".6s">
                              <div className="footer__widget-title">
                                 <h4>Ресурсы</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><a href="https://1ord.ru/assets/workwith1ord.pdf" target="_blank" rel="noreferrer">Презентация</a></li>
                                       <li><a href="https://sozd.duma.gov.ru/bill/1173900-7" target="_blank" rel="noreferrer">О законе</a></li>
                                       <li><a href="https://adtech.beeline.ru/xwiki/bin/view/%D0%9E%D0%A0%D0%94/API%20%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F/" target="_blank" rel="noreferrer">Документация по API</a></li>
                                       <li><a href="https://adtech.beeline.ru/xwiki/bin/view/ОРД/" target="_blank" rel="noreferrer">Пример договора</a></li>
                                       <li><a href="#faq">Помощь</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3 col-sm-4">
                           <div className="footer__widget wow fadeInUp" data-wow-delay=".8s">
                              <div className="footer__widget-title">
                                 <h4>Контакты</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__info">
                                    <ul>
                                       <li>
                                          <div style={{minWidth:'140px'}} className="theme-color">
                                          Новым клиентам
                                          </div>
                                          <div  className="text theme-color">
                                          <span><a href={`mailto:${emailForNewClients}`}>{emailForNewClients}</a></span>
                                          </div>
                                       </li>
                                       <li>
                                          <div style={{minWidth:'140px'}} className="theme-color">
                                            Поддержка
                                          </div>
                                          <div  className="text theme-color" >
                                          <span><a href={`mailto:${supportEmail}`}>{supportEmail}</a></span>
                                          </div>
                                             </li>
                                       <li>
                                          <div style={{minWidth:'140px'}} className="theme-color">
                                          Телеграм
                                          </div>
                                          <div  className="text theme-color">
                                          <span><a href={telegram} target="_blank" rel="noreferrer">Первый ОРД</a></span>
                                          </div>
                                             </li>
                                    </ul>
                                 </div>
    
                                  </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer__copyright-3">
                  <div className="container">
                     <div className="footer__copyright-inner">
                        <div className="row align-items-center">
                           <div className="col-xl-12">
                              <div className="footer__copyright-text text-center">
                                 <p>Оператор рекламных данных — ООО «Первый ОРД» © {currentYear}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default HomeFooter;
